html body {
  background-color: white;
}

.MuiGrid-root {
  width: 100%;
}

@keyframes bounceIn {
  0% {
    opacity: 1;
    transform: scale(.3);
  } 

  50% {
    opacity: 1;
    transform: scale(1.05);
  } 

  70% {
    opacity: 1;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
